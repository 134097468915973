<script>

export default {
	components: {
		Navbar: () => import('@/router/components/Navbar'),
		Footer: () => import('@/router/components/Footer'),
	},
  data() {
    return {

    };
  },
  created() {
    window.addEventListener("scroll", this.windowScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll);
  },
  mounted() {

  },
  methods: {
    /**
     * Window scroll method
     */
    windowScroll() {
      const navbar = document.getElementById("navbar");
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      document.getElementById("topnav-menu-content").classList.toggle("show");
    }
  }
};
</script>

<template>
  <div>
	  <Navbar :isHomepage="false"/>
    <div>
      <section class="section pt-4 bg-white mt-5">
        <div class="mt-5 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="text-center mb-5">
                  <h4>Terms of Use</h4>
                </div>
              </div>
            </div>
            <div>
              <div>
                Last updated May 31, 2021
              </div>
              <div>
                <h4>CONTENT LICENSE AGREEMENT</h4>
                <p>This Content License Agreement constitutes a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and CFTools Software GmbH, Kiem-Pauli-Straße 8, 84036 Landshut (" Company ", "we", "us", or "our"), concerning the use of your content by us.</p>
              </div>
              <div>
                <h4>1. SUBJECT MATTER OF THE AGREEMENT</h4>
                <p>
                  (1) The subject matter of this agreement is the content made available by you through the Steam Workshop or using any of our systems.
                </p>
                <p>
                  (2) You are the owner of the trademark, content or material.
                </p>
                <p>
                  (3) You have not and will not grant any other licenses to any entity - that is competing with us - for any content subject to this license.
                </p>
              </div>
              <div>
                <h4>2. LICENSE</h4>
                <p>
                  (1) You hereby authorize use to use your content and material designated in 1. .
                </p>
                <p>
                  (2) This license covers all commercial and non commercial use by us.
                </p>
                <p>
                  (3) This license is issued perpetually and at no cost to us.
                </p>
              </div>
              <div>
                <h4>3. TERM OF CONTRACT</h4>
                <p>
                  (1) The term of contract shall be as long as the content is made available by you or used by us.
                </p>
              </div>
              <div>
                <h4>4. SEVERABILITY CLAUSE</h4>
                <p>
                  (1) If any provision of this Agreement is or becomes invalid or unenforceable, the remaining provisions of this Agreement shall not be affected thereby.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Footer start -->
	    <Footer :isHomepage="false"/>
      <!-- Footer end -->
    </div>
  </div>
</template>